import React, { Suspense } from 'react'
import { Routes, Route, BrowserRouter } from 'react-router-dom'
import './i18n';


// import Button from 'react-bootstrap/Button';

const Home = React.lazy(() => import('./pages/Home'))

function Loading() {
	return <p>Loading...</p>
}

function App() {
	return (
		<div className='App'>
			<BrowserRouter>
				{/* <Link to='/'>Home</Link> */}
				
				<Routes>
					<Route
						path='/'
						element={
							<Suspense fallback={Loading}>
								<Home />
							</Suspense>
						}
					/>
				</Routes>
			</BrowserRouter>
		</div>
	)
}

export default App
