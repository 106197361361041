import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const resources = {
    en: {
        translation: {
            tecnologies: "Tech Stack",
            projects: "Projects",
            contact: "Contact",
            greeting: "I'm Esmeralda",
            profile: "Front End developer",
            aboutMe: "I've contributed in multidisciplinary teams in assurance companies, software factories, marketing and ecommerce agencies, using the best practices to deliver innovative solutions for each project.",
            myProjectsBtn: "My projects"

        },
    },
    es: {
        translation: {
            tecnologies: "Tecnologías",
            projects: "Proyectos",
            contact: "Contacto",
            greeting: "Soy Esmeralda",
            profile: "Desarrolladora Front End",
            aboutMe: "He colaborado con equipos multidisciplinarios en aseguradoras, fábricas de software, agencias de marketing y comercio electrónico, usando las mejores prácticas para entregar soluciones innovadoras y funcionales que marcan la diferencia en cada proyecto.",
            myProjectsBtn: "Mis proyectos"

        },
    },
};

i18n.use(initReactI18next).init({
    resources,
    lng: 'es', // Idioma predeterminado
    fallbackLng: 'en', // Idioma de reserva si no se encuentra la traducción
    interpolation: {
        escapeValue: false, // React ya se encarga de evitar XSS
    },
});

export default i18n;
